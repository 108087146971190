import {createHashRouter, Navigate, RouterProvider} from "react-router-dom";
import {Home} from "./pages";
import Events from "./pages/events";
import Form from "./pages/form";
import Price from "./pages/price";
import Rent from "./pages/rent";
import Reviews from "./pages/reviews";
import Season from "./pages/season";
import Post from "./pages/post";

const publicRoutes = [
  {
    path: '/post/:id',
    element: <Post />,
  },
  {
    path: '/season',
    element: <Season />,
  },
  {
    path: '/reviews',
    element: <Reviews />,
  },
  {
    path: '/rent',
    element: <Rent />,
  },
  {
    path: '/price',
    element: <Price />,
  },
  {
    path: '/form',
    element: <Form />,
  },
  {
    path: '/events',
    element: <Events />,
  },
  {
    path: '/about',
    element: <Home />,
  },
  { path: '*', element: <Navigate to={'/about'} /> },
]

const router = createHashRouter(publicRoutes)

export const Router = () => {
  return <RouterProvider router={router} />
}