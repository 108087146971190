import Layout from "../components/layout";

const Rent = () => {
  return (
    <Layout title='Аренда декора'>
      Rent
    </Layout>
  );
};

export default Rent;