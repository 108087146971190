import Layout from "../components/layout";
import { useSelector } from "react-redux";
import QuoteOpen from "../images/quoteOpen";
import QuoteClose from "../images/quoteClose";
import { motion } from "framer-motion";

const variantsText = {
  hidden: {
    scale: 0.5,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      delay: 0.2,
      duration: 0.7,
    },
  },
};
const variantsImg = {
  hidden: {
    scale: 0.5,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      delay: 0.2,
      duration: 0.7,
    },
  },
};
const Reviews = () => {
  const reviews = useSelector((state) => state.reviews.reviews);

  return (
    <Layout title="Отзывы">
      <motion.section
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.1, once: true }}
        className="reviews"
      >
        <div className="container">
          <motion.h1 variants={variantsText}>ОТЗЫВЫ</motion.h1>
          <div className="reviewsItems">
            {reviews.map((el) => (
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ amount: 0.1, once: true }}
                variants={variantsImg}
                className="reviewsItem"
                key={el.id}
              >
                <div className="reviewsItemImg">
                  <img
                    src={el.img}
                    alt="img"
                  />
                </div>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ amount: 0.1, once: true }}
                  variants={variantsText}
                >
                  <h2>{el.title}</h2>
                  <span className="quoteOpen">
                    <QuoteOpen />
                  </span>
                  <p>{el.text}</p>
                  <span className="quoteClose">
                    <QuoteClose />
                  </span>
                </motion.div>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>
    </Layout>
  );
};

export default Reviews;
