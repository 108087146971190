import React from 'react';

const Telegram = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.60863 13.0238C7.89 10.2296 12.0786 8.38748 14.1743 7.49746C20.1582 4.95627 21.4015 4.51484 22.212 4.50011C22.3902 4.49706 22.7887 4.54216 23.0469 4.75604C23.2649 4.93664 23.3249 5.1806 23.3536 5.35183C23.3823 5.52305 23.418 5.91311 23.3896 6.21789C23.0653 9.69658 21.6622 18.1385 20.9484 22.0347C20.6464 23.6833 20.0517 24.2361 19.4759 24.2902C18.2246 24.4077 17.2745 23.4459 16.0625 22.6347C14.1661 21.3655 13.0948 20.5754 11.254 19.3368C9.12662 17.9054 10.5057 17.1187 11.7181 15.833C12.0354 15.4966 17.5485 10.3766 17.6552 9.91209C17.6685 9.854 17.6809 9.63747 17.5549 9.52313C17.429 9.4088 17.243 9.4479 17.1088 9.47899C16.9186 9.52307 13.8891 11.5676 8.02019 15.6125C7.16026 16.2154 6.38137 16.5092 5.68351 16.4938C4.91417 16.4768 3.43427 16.0496 2.33412 15.6845C0.984742 15.2366 -0.0877184 14.9999 0.00566887 14.2393C0.0543107 13.8431 0.58863 13.438 1.60863 13.0238Z" fill="#7F7F7F" />
    </svg>
  );
};

export default Telegram;