import { createSlice } from "@reduxjs/toolkit";
import image1 from '../images/reviews/1-1.jpg'
import image2 from '../images/reviews/1-2.jpg'
import image3 from '../images/reviews/1-3.jpg'

const initialState = {
  
  reviews:[
    {
      id: 1,
      img: image1,
      title: 'Диана и Фарид',
      text: 'То, что вы сделали –это просто невероятно красиво! Стол, свечи, бантики, зона церемонии - всебыло просто прекрасно.Мы и все гости остались в огромнейшем восторге. Эти карточки, тарелочки, все так нежно, по- уютному, полностью был передан настрой, который я хотела, все мои пожелания были учтены. Букет невесты идеально подходил к моему образу. Декор сделал эту свадьбу, 70 % от свадьбы –это заслуга декораторов, вас, спасибо вам большое!'
    },
    {
      id: 2,
      img: image2,
      title: 'Анастасия и Иван',
      text: 'Когда мы пришли на площадку, во время подготовки декора, стояли в ступоре от того как же все красиво! Какая огромная команда профессионалов работает над оформлением, было видно, как все стараются, делают «с душой». Мы получили кучу комплиментов по поводу оформления нашей свадьбы, был даже «Это самая красивая свадьба, на которой мы были». Cпасибо вам большое за вашу работу, за отзывчивость и помощь в реализации всех наших идей.'
    },
    {
      id: 3,
      img: image3,
      title: 'Татьяна и Иван',
      text: 'От нас с Ваней хочу сказать вам огромное спасибо! Вы воплотили в жизнь картинку моей идеальной свадьбы, все было так, как я и представляла! Волшебная зона церемонии просто покорила меня, букет был прекрасен и идеально вписывался в концепцию, за него отдельное спасибо. Зона банкета с фонариками понравилась всем гостям и была очень уютная атмосфера. Спасибо вам, девочки, большое, не знаю, что бы я без вас делала!'
    },
  ]
};

export const reviewsSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {
  },
});

// Action creators are generated for each case reducer function
// export const { } = reviewsSlice.actions;

export default reviewsSlice.reducer;
