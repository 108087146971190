import {createSlice} from "@reduxjs/toolkit";

import season1_1 from '../images/season/1-1.jpg'
import season1_2 from '../images/season/1-2.jpg'
import season1_3 from '../images/season/1-3.jpg'
import season2_1 from '../images/season/2-1.jpg'
import season2_2 from '../images/season/2-2.jpg'
import season2_3 from '../images/season/2-3.jpg'
import season3_1 from '../images/season/3-1.jpg'
import season3_2 from '../images/season/3-2.jpg'
import season3_3 from '../images/season/3-3.jpg'
import season4_1 from '../images/season/4-1.jpg'
import season4_2 from '../images/season/4-2.jpg'
import season4_3 from '../images/season/4-3.jpg'
import season5_1 from '../images/season/5-1.jpg'
import season5_2 from '../images/season/5-2.jpg'
import season5_3 from '../images/season/5-3.jpg'
import season6_1 from '../images/season/6-1.jpg'
import season6_2 from '../images/season/6-2.jpg'
import season6_3 from '../images/season/6-3.jpg'


const initialState = {

  seasons: [
    {
      id: 1,
      title: "Оформление фасада",
      price: "от 50 тыс.",
      images: [
        season1_1, season1_2, season1_3
      ],
    },

    {
      id: 2,
      title: "Оформление фасада",
      price: "от 100 тыс.",
      images: [
        season2_1, season2_2, season2_3
      ],
    },
    {
      id: 3,
      title: "Оформление фасада",
      price: "от 250 тыс.",
      images: [
        season3_1, season3_2, season3_3
      ],
    },
    {
      id: 4,
      title: "Оформление внутренних пространств",
      price: "от 100 тыс.",
      images: [
        season4_1, season4_2, season4_3
      ],
    },
    {
      id: 5,
      title: "Композиции для салонов",
      price: "от 5 тыс.",
      images: [
        season5_1, season5_2, season5_3
      ],
    },
    {
      id: 6,
      title: "Ёлка под ключ",
      price: "высота 2,4 м от 80 тыс.",
      images: [
        season6_1, season6_2, season6_3
      ],
    },
  ],

};

export const seasonSlice = createSlice({
  name: "season",
  initialState,
  reducers: {},
});

// Action creators are generated for each case reducer function
// export const { } = slice.seasonSlice;

export default seasonSlice.reducer;
