import Layout from "../components/layout";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination} from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import React from "react";
import useScreenWidth from "../hooks/use-screen-width";
import { motion } from "framer-motion";
import {Link} from "react-router-dom";

import image1 from '../images/1.svg'
import image2 from '../images/2.svg'
import image3 from '../images/3.svg'
import image4 from '../images/4.svg'
import image5 from '../images/5.svg'
import image6 from '../images/6.svg'
import image7 from '../images/7.svg'

const service = [
  {
    id: 1,
    title: "Семейный ужин",
    text: "на +/- 20 человек",
    price: "от 30 тыс.",
  },
  {
    id: 2,
    title: "Оформление банкета",
    text: "стол молодожёнов гостевые столы(6 шт) букет невесты рассадка",
    price: "от 80 тыс.",
  },
  {
    id: 3,
    title: "Оформление банкета + церемония",
    text: "стол молодожёнов гостевые столы(6 шт) букет невесты рассадка  церемония",
    price: "от 120 тыс.",
  },
  {
    id: 4,
    title: "Оформление банкета + церемония",
    text: "индивидуальный проект с разработкой концепции и изготовлением конструкций",
    price: "от 250 тыс.",
  },
];

const calculate = [
  {
    img: image1,
    title: "ЗАПОЛНЕНИЕ БРИФА и запись на встречу",
    text: "Для уточнения вводных данных и ваших пожеланий мы отправляем вам короткий бриф, на основе которого мы подготовим предложение на первую встречу.",
  },
  {
    img: image2,
    title: "ПЕРВАЯ ВСТРЕЧА",
    text: "Презентуем варианты оформления, рассказываем подробно, из чего строится смета, какие позиции включает, отвечаем на вопросы.",
  },
  {
    img: image3,
    title: "ДОГОВОР",
    text: "Если наши условия вам подходят, то мы заключаем договор, вы вносите задаток для бронирования даты, который будет учитываться в общей предоплате.",
  },
  {
    img: image4,
    title: "СОГЛАСОВАНИЕ сметы и проекта",
    text: "   ",
  },
  {
    img: image5,
    title: "ПРЕДОПЛАТА",
    text: "Внесение предоплаты в размере 30%, остальная часть оплачивается за 2 недели до вашего мероприятия.",
  },
  {
    img: image6,
    title: "ПОДГОТОВКА И РЕАЛИЗАЦИЯ проекта",
    text: "   ",
  },
  {
    img: image7,
    title: "ОБРАТНАЯ СВЯЗЬ",
    text: "Всегда рады получить от вас отзыв о работе с нами!",
  },
];

const ServiceItem = ({ el }) => {
  return (
    <>
      <h2>{el.title}</h2>
      <div>
        <p>{el.text}</p>
        <b>{el.price}</b>
      </div>
    </>
  );
};

const Slider = ({ el }) => {
  return (
    <Swiper
      pagination={{
        clickable: true,
      }}
      modules={[Pagination]}
      className="mySwiperPrice"
    >
      {el.map((el) => (
        <SwiperSlide key={el.id} className="serviceItemSlider">
          <ServiceItem el={el} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

const variantsTextLeft = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.2,
      duration: 0.7,
    },
  },
};
const variantsTextRight = {
  hidden: {
    x: 100,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.2,
      duration: 0.7,
    },
  },
};

const variantsItem = {
  hidden: {
    scale: 0,
    opacity: 0.5,
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      delay: 0.2,
      duration: 0.7,
    },
  },
};
const Price = () => {
  let isScreen = useScreenWidth();
  return (
    <Layout title="Прайс">
      <motion.section
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.1, once: true }}
        className="price"
      >
        <div className="container">
          <div className="priceText">
            <motion.h1 variants={variantsTextLeft}>Прайс</motion.h1>
            <motion.div variants={variantsTextRight}>
              <p>
                Стоимость зависит от многих факторов: количество гостей, столов,
                объёмы оформления, наличие арендного текстиля, арендной мебели,
                количество и состав флористики, зоны оформления, монтажные и
                высотные работы, транспортные расходы, удалённость площадки.
              </p>
              <p>
                Мы с радостью поможем и дадим вам более точный ответ после
                уточнения вводной
              </p>
              <p>
                информации и всех пожеланий, сделаем детальный расчёт сметы.
              </p>
            </motion.div>
          </div>

          <motion.div variants={variantsItem}>
             {isScreen > 850 ? (
            <div className="serviceItems">
              {service.map((el) => (
                <div
                  key={el.id}
                  className="serviceItem"
                >
                  <ServiceItem el={el} />
                </div>
              ))}
            </div>
          ) : (
            <div>
              <Slider el={service} />
            </div>
          )}
          </motion.div>
         

          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className="calculate"
          >
            <Link to="/">рассчитать смету</Link>
          </motion.div>
          <motion.h1
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.1, once: true }}
            variants={variantsItem}
          >
            Этапы работы
          </motion.h1>
          <div className="calculateItems">
            {calculate.map((el) => (
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ amount: 0.1, once: true }}
                variants={variantsItem}
                className="calculateItem"
                key={el.img}
              >
                <div>
                  <span>
                    <img
                      src={el.img}
                      alt="num"
                      width={14}
                      height={22}
                    />
                  </span>
                </div>

                <h4>{el.title}</h4>
                <p>{el.text}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>
    </Layout>
  );
};

export default Price;
