import React from 'react';

const QuoteOpen = () => {
  return (
    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="19" height="16" fill="#1E1E1E" />
      <g id="&#208;&#158;&#208;&#162;&#208;&#151;&#208;&#171;&#208;&#146;&#208;&#171;">
        <rect width="1600" height="1314" transform="translate(-150 -846)" fill="white" />
        <path id="&#226;&#128;&#156;" d="M5.24903 10.5281C6.13619 10.5281 6.8262 10.7821 7.31907 11.29C7.86122 11.798 8.13229 12.4444 8.13229 13.2294C8.13229 15.0765 6.9987 16 4.73152 16C3.25292 16 2.09468 15.4921 1.25681 14.4762C0.418936 13.4141 0 11.9134 0 9.97403C0 8.12699 0.44358 6.41847 1.33074 4.84849C2.2179 3.23233 3.524 1.63925 5.24903 0.0692673C5.29831 0.0230891 5.37224 0 5.47082 0C5.61868 0 5.74189 0.0692645 5.84047 0.207794C5.93904 0.346323 5.93904 0.461763 5.84047 0.554114C3.42542 2.86292 2.2179 5.5873 2.2179 8.72727C2.2179 10.0202 2.46433 10.9668 2.9572 11.5671C3.40078 10.8745 4.16472 10.5281 5.24903 10.5281ZM16.1167 10.5281C17.0039 10.5281 17.6939 10.7821 18.1868 11.29C18.7289 11.798 19 12.4444 19 13.2294C19 15.0765 17.8664 16 15.5992 16C14.1206 16 12.9624 15.4921 12.1245 14.4762C11.2866 13.4141 10.8677 11.9134 10.8677 9.97403C10.8677 8.12699 11.3113 6.41847 12.1984 4.84849C13.0856 3.23233 14.3917 1.63925 16.1167 0.0692673C16.166 0.0230891 16.2399 0 16.3385 0C16.4864 0 16.6096 0.0692645 16.7082 0.207794C16.8067 0.346323 16.8067 0.461763 16.7082 0.554114C14.2931 2.86292 13.0856 5.5873 13.0856 8.72727C13.0856 10.0202 13.332 10.9668 13.8249 11.5671C14.2685 10.8745 15.0324 10.5281 16.1167 10.5281Z" fill="black" />
      </g>
    </svg>
  );
};

export default QuoteOpen;