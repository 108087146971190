import Layout from "../components/layout";
import { useSelector } from "react-redux";
import useScreenWidth from "../hooks/use-screen-width";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const variantsTextLeft = {
  hidden: {
    x: -50,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.2,
      duration: 0.7,
    },
  },
};
const variantsTextRight = {
  hidden: {
    x: 50,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.2,
      duration: 0.7,
    },
  },
};
const variantsUp = {
  hidden: {
    y: 0,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.1,
      duration: 1.5,
    },
  },
};

const SeasonImgItem = ({ element }) => {
  return (
    <div className="seasonImgItem" key={element}>
      <img src={element} alt="img" width='100%' />
    </div>
  );
};
const Slider = ({ season }) => {
  // console.log(season.images);
  return (
    <Swiper
      pagination={{
        clickable: true,
      }}
      modules={[Pagination]}
      className="mySwiper"
    >
      {season.images.map((img) => (
        <SwiperSlide key={img}>
          <SeasonImgItem element={img} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
const Season = () => {
  const seasons = useSelector((state) => state.season.seasons);
  const width = useScreenWidth();
  console.log(width);
  return (
    <Layout title="Сезонное оформление">
      <motion.section
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.1, once: true }}
        className="season"
      >
        <div className="container">
          <motion.h1 variants={variantsTextLeft}>СЕЗОННОЕ ОФОРМЛЕНИЕ</motion.h1>
          <motion.div variants={variantsTextRight}>
            <p>
              Предложение для вашего бизнеса. Помимо свадеб мы занимаемся
              сезонным оформлением витрин и внутренних пространств. Стоимость
              оформления зависит от объёмов работ, материалов и зон оформления.
            </p>
            <p>Выезд на замер и расчёт сметы - БЕСПЛАТНЫЙ.</p>
          </motion.div>

          <div className="seasonItems">
            {seasons.map((season) => (
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ amount: 0.1, once: true }}
                variants={variantsUp}
                className="seasonItem"
                key={season.id}
              >
                <h2>{season.title}</h2>
                <p>{season.price}</p>
                <div className="seasonImg">
                  {width > 690 ? (
                    season.images.map((element) => (
                      <SeasonImgItem key={element} element={element} />
                    ))
                  ) : (
                    <Slider season={season} />
                  )}
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>
    </Layout>
  );
};

export default Season;
