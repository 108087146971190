import { configureStore } from '@reduxjs/toolkit'
import eventsReducer from './eventsSlice'
import reviewsReducer from './reviewsSlice'
import seasonReducer from './seasonSlice'

export const store = configureStore({
  reducer: {
    events: eventsReducer,
    reviews: reviewsReducer,
    season: seasonReducer
  },
})