import React from 'react';

const Vk = () => {
  return (
      <svg width="20" height="20" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Vkontakte (VK)">
          <g id="Vkontakte (VK)_2">
            <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M25.0245 8.06747C25.1925 7.51289 25.0245 7.10527 24.2252 7.10527H21.5823C20.9103 7.10527 20.6007 7.45735 20.4327 7.84571C20.4327 7.84571 19.0886 11.0904 17.1846 13.198C16.5686 13.8082 16.2886 14.0021 15.9526 14.0021C15.7846 14.0021 15.5415 13.8082 15.5415 13.2536V8.06747C15.5415 7.40182 15.3465 7.10527 14.7864 7.10527H10.6332C10.2134 7.10527 9.96082 7.41416 9.96082 7.70697C9.96082 8.33803 10.9126 8.4835 11.0108 10.2583V14.1133C11.0108 14.9585 10.8568 15.1118 10.5207 15.1118C9.6248 15.1118 7.44555 11.8523 6.1528 8.12301C5.89946 7.39808 5.64536 7.10527 4.96992 7.10527H2.32703C1.57192 7.10527 1.4209 7.45735 1.4209 7.84571C1.4209 8.53904 2.31703 11.978 5.59288 16.5261C7.77704 19.6318 10.8537 21.3158 13.6537 21.3158C15.3338 21.3158 15.5415 20.9418 15.5415 20.2977V17.9501C15.5415 17.2022 15.7006 17.053 16.2324 17.053C16.6247 17.053 17.2965 17.2471 18.8647 18.7446C20.6566 20.5193 20.9518 21.3158 21.9599 21.3158H24.6028C25.3579 21.3158 25.7355 20.9418 25.5176 20.2038C25.2794 19.4682 24.4238 18.4013 23.2885 17.1362C22.6725 16.415 21.7485 15.6385 21.4687 15.2503C21.0766 14.7511 21.1886 14.5293 21.4687 14.0858C21.4687 14.0858 24.6885 9.59305 25.0245 8.06766V8.06747Z" fill="#7F7F7F" />
          </g>
        </g>
      </svg>
  );
};

export default Vk;