import Whatsapp from "../images/whatsapp"
import Vk from "../images/vk"
import Telegram from "../images/telegram"
import Instagram from "../images/instagram"
import { motion } from "framer-motion";

const social = [
  {
    icon: Whatsapp,
    link: "https://wa.me/89233206061",
  },
  {
    icon: Instagram,
    link: "https://www.instagram.com/flohall_decor?igsh=aWQyYjg4YWRxY2Fh",
  },
  {
    icon: Telegram,
    link: "https://t.me/kristina_a2601",
  },
  {
    icon: Vk,
    link: "https://vk.com/flohall_decor",
  },
]

const variants = {
  hidden: {
    x: 30,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.1,
      duration: 0.6,
    },
  },
};

const Footer = () => {
  return (
    <motion.footer
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.1, once:true }}
      variants={variants}
      className="footer">
      <div className="container">
        <div className="footerItems">
          <div className="item">
            <h6>СОЦ.СЕТИ:</h6>
            <p className="socialItems">
              {social.map((el) => (
                <motion.a
                  whileHover={{ scale: 1.4 }}
                  whileTap={{ scale: 0.8 }}
                  href={el.link} key={el.link} target="_blank" >
                  <el.icon />
                </motion.a>
              ))}
            </p>
          </div>

          <div className="item">
            <h6>E-MAIL:</h6>
            <motion.a
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              href="mailto:flohall@yandex.ru">flohall@yandex.ru</motion.a>
          </div>
          <div className="item">
            <h6>ТЕЛЕФОН:</h6>
            <motion.a
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              href="tel:89233206061" type='phone'>8 923 320 60 61</motion.a>
            <motion.a
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              href="tel:89504227731" type='phone'>8 950 422 77 31</motion.a>
          </div>
        </div>
      </div>
    </motion.footer>
  );
};

export default Footer;
