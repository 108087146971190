import Layout from "../components/layout";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import {Link} from "react-router-dom";
// import useScrollDirection from "../components/hooks/use-scroll";

const variantsDown = {
  hidden: {
    y: 100,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 0.8,
    },
  },
};

const variantsText = {
  hidden: {
    scale: 0.5,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 0.8,
    },
  },
};
const Events = () => {
  const events = useSelector((state) => state.events);
  const arrEvents = Object.keys(events);

  return (
    <Layout title="События">
      <section className="events">
        <div className="container">
          <motion.h1
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.2, once: true }}
          variants={variantsText}>События</motion.h1>
          <div className="eventItems">
            {arrEvents.map((el) => (
              <Link to={`/post/${el}`} key={el}>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ amount: 0.2, once: true }}
                  variants={variantsDown}
                  whileHover={{
                    opacity: 0.5,
                    transition: {
                      duration: 0.5,
                    },
                  }}
                  className="eventItem"
                >
                  <motion.div whileTap={{ scale: 0.9 }}>
                    <img
                      src={events[el].img}
                      alt="event"
                      width='100%'
                    />
                  </motion.div>
                  <motion.h2
                    whileHover={{ scale: 1.1, transition: { duration: 0.3 } }}
                    whileTap={{ scale: 0.9 }}
                  >
                    {events[el].title}
                  </motion.h2>
                </motion.div>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Events;
