import Layout from "../components/layout";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import {useParams} from "react-router-dom";

const variants = {
  hidden: {
    x: -10,
    opacity: 0,
  },
  visible: (custom) => ({
    x: 0,
    opacity: 1,
    transition: {
      delay: custom * 0.2,
      duration: 0.7,
    },
  }),
};
const variantsText = {
  hidden: {
    scale: 0.5,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      delay: 0.2,
      duration: 0.7,
    },
  },
};

const post = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const params = useParams();
  const id = params.id;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const event = useSelector((state) => state.events[id]);

  return event && (
    <Layout>
      <motion.section
        initial="hidden"
        whileInView="visible"

        className="post">
        <div className="container">
          <motion.h1
            variants={variantsText}
          >{event.title}</motion.h1>
          <div className="postItems">
            {event.photos?.map((el,i) => (
              <div className="postItem" key={el}>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ amount: 0.2, once: true }}
                  variants={variants}
                  custom={i}
                >
                  <img
                    src={el}
                    alt="photo"
                    width='100%'
                  />
                </motion.div>
              </div>
            ))}
          </div>
        </div>
      </motion.section>
    </Layout>
  )
};

export default post;
