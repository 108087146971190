import Layout from "../components/layout";
import {motion} from "framer-motion";
import {Link} from "react-router-dom";
import {useState} from "react";
import axios from "axios";


const variantsLeft = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.2,
      duration: 0.7,
    },
  },
};
const variantsRight = {
  hidden: {
    x: 100,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      delay: 0.2,
      duration: 0.7,
    },
  },
};
const variantsText = {
  hidden: {
    scale: 0.5,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      delay: 0.2,
      duration: 0.7,
    },
  },
};
const Form = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [telephone, setTelephone] = useState('')
  const [date, setDate] = useState('')
  const [desc, setDesc] = useState('')

  const submit = async () => {
    try {
      const form = new FormData();
      form.append('name', name);
      form.append('from', 'andreymaranchak@yandex.ru');
      form.append('subject', 'My letter subject');
      form.append('to', 'andreymaranchak@yandex.ru');
      form.append('html', '<html><head></head><body>' +
        `<p>${name}</p>` +
        `<p>${email}</p>` +
        `<p>${telephone}</p>` +
        `<p>${date}</p>` +
        `<p>${desc}</p>` +
        '</body></html>');

      const response = await axios.post('https://api.smtp.bz/v1/smtp/send', form, {
        headers: {
          Authorization: ' ',
          ...form.getHeaders(),
        },
      });

      console.log('Email sent:', response.data);
    } catch (error) {
      console.error('Error sending email:', error);
    }
  }

  const handleSubmit = () => {
    // @ts-ignore
    document.getElementById('form-secret').value = 'form-secret';
  }

  return (
    <Layout title="Оставить заявку">
      <motion.section
        initial="hidden"
        whileInView="visible"
        viewport={{amount: 0.1, once: true}}
        className="form">
        <div className="container">
          <motion.h1
            variants={variantsText}
          >ОСТАВИТЬ ЗАЯВКУ НА РАСЧЁТ СМЕТЫ
          </motion.h1>
          <form action='https://flohall.ru/mail.php' method='POST'>
            <div className="formWrap">
              <motion.div
                variants={variantsLeft}
                className="formInputs">
                <input type="text" placeholder="Имя" name='name'/>
                <input type="email" placeholder="E-mail" name='email'/>
                <input type="phone" placeholder="Телефон" name='phone'/>
                <input type="text" placeholder="Дата события" name='date'/>
              </motion.div>

              <motion.textarea
                variants={variantsRight}
                placeholder="Опишите планируемое событие"
                name='desc'>
              </motion.textarea>
              <input type="hidden" name='secret' id='form-secret' value=''/>
            </div>
            <motion.div
              whileHover={{scale: 1.1}}
              whileTap={{scale: 0.9}}
              className="formButtom">
              <button type='submit' onClick={handleSubmit}>отправить заявку</button>
            </motion.div>

          </form>

        </div>
      </motion.section>
    </Layout>
  );
};

export default Form;
