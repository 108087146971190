import React from 'react';

const QuoteClose = () => {
  return (
    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="&#226;&#128;&#156;" d="M13.751 5.47186C12.8638 5.47186 12.1738 5.21789 11.6809 4.70995C11.1388 4.20202 10.8677 3.55555 10.8677 2.77056C10.8677 0.923519 12.0013 -6.11846e-07 14.2685 -4.13642e-07C15.7471 -2.84379e-07 16.9053 0.507935 17.7432 1.52381C18.5811 2.58586 19 4.08658 19 6.02597C19 7.87301 18.5564 9.58153 17.6693 11.1515C16.7821 12.7677 15.476 14.3607 13.751 15.9307C13.7017 15.9769 13.6278 16 13.5292 16C13.3813 16 13.2581 15.9307 13.1595 15.7922C13.061 15.6537 13.061 15.5382 13.1595 15.4459C15.5746 13.1371 16.7821 10.4127 16.7821 7.27272C16.7821 5.9798 16.5357 5.03319 16.0428 4.4329C15.5992 5.12554 14.8353 5.47186 13.751 5.47186ZM2.88327 5.47186C1.99611 5.47186 1.3061 5.21789 0.813232 4.70995C0.271079 4.20202 1.08793e-06 3.55555 1.15655e-06 2.77056C1.31803e-06 0.923518 1.13359 -1.56193e-06 3.40078 -1.36373e-06C4.87938 -1.23446e-06 6.03762 0.507934 6.87549 1.52381C7.71336 2.58586 8.1323 4.08658 8.1323 6.02597C8.1323 7.87301 7.68872 9.58153 6.80156 11.1515C5.9144 12.7677 4.6083 14.3607 2.88327 15.9307C2.83398 15.9769 2.76005 16 2.66148 16C2.51362 16 2.3904 15.9307 2.29183 15.7922C2.19326 15.6537 2.19326 15.5382 2.29183 15.4459C4.70688 13.1371 5.9144 10.4127 5.9144 7.27272C5.9144 5.9798 5.66797 5.03319 5.1751 4.4329C4.73152 5.12554 3.96758 5.47186 2.88327 5.47186Z" fill="black" />
    </svg>
  );
};

export default QuoteClose;