import { motion } from "framer-motion";
import {Link, useLocation} from "react-router-dom";
import logo from '../images/logo.png'
const nav = [
  {
    link: "/about",
    name: "О Нас",
  },
  {
    link: "/price",
    name: "Прайс",
  },
  {
    link: "/events",
    name: "События",
  },
  {
    link: "/season",
    name: "Сезонные оформления",
  },
  {
    link: "/form",
    name: "Оставить заявку",
  },
  {
    link: "/reviews",
    name: "Отзывы",
  },
];

const variantsText = {
  hidden: {
    y: -50,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.2,
      duration: 0.7,
    },
  },
};
const Header = () => {
  const location = useLocation();
  return (
    <motion.header
      initial="hidden"
      whileInView="visible"
      viewport={{amount:0.1, once: true}}

      className="header">
      <div className="container">
        <motion.span
          variants={variantsText}
          className="logo">
          <img
            src={logo}
            alt="Logo"
          />
        </motion.span>
        <input type="checkbox" id="burger" />
        <label htmlFor="burger" className="menu"></label>

        <nav className="nav">
          {nav.map((el) => (
            <motion.div
              key={el.link}
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.1, once: true }}
              variants={variantsText}
              whileHover={{ scale: 1.1}}
              whileTap={{ scale: 0.9}}
              className={location.pathname == el.link ? "active" : ""}
            >
              <Link to={el.link}>
                {el.name}
              </Link>
            </motion.div>

          ))}
        </nav>

      </div>
    </motion.header>
  );
};

export default Header;
