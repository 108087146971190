import Layout from "../components/layout";
import {motion} from "framer-motion";
import logo from '../images/about.jpg'
const social = [
  {
    icon: "/images/whatsapp.svg",
    link: "http://whatsapp.com",
  },
  {
    icon: "/images/instagram.svg",
    link: "http://instagram.com",
  },
  {
    icon: "/images/telegram.svg",
    link: "http://web.telegram.org",
  },
  {
    icon: "/images/vk.svg",
    link: "http://m.vk.com",
  },
];

const variants = {
  hidden: {
    y: 30,
    opacity: 0,
  },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: {
      delay: custom * 0.2,
      duration: 0.3,
    },
  }),
};

const variantsImg = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 1,
    },
  },
};

const About = () => {
  return (
    <Layout title="О Нас">
      <motion.section
        initial="hidden"
        whileInView="visible"
        viewport={{amount: 0.1}}
        className="about">
        <motion.span
          initial="hidden"
          whileInView="visible"
          variants={variantsImg}
          className="aboutImg">
          <img
            src={logo}
            alt="Logo"
            width='100%'
          />
        </motion.span>
        <div className="container">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{amount: 0.1, once: true}}
          >
            <motion.h1
              custom={1}
              variants={variants}
            >
              О Нас
            </motion.h1>

            <motion.h3
              custom={2}
              variants={variants}
            >
              Мы – Катя и Кристина, основатели и руководители студии декора
              &quot;FLOHALL&quot;.
            </motion.h3>
            <motion.div
              custom={3}
              variants={variants}
              className="aboutText"
            >
              <p>
                За шесть лет нашей работы мы оформили более 250 событий, таких
                как: свадьбы, дни рождения, корпоративные мероприятия, так же
                украшаем витрины и внутренний интерьер ресторанов, кафе,
                салонов.
              </p>
            </motion.div>
            <motion.div
              custom={4}
              variants={variants}
            >
              <p>
                Для нас важен результат и качество нашей работы. Умеем слушать и
                слышать наших клиентов!
              </p>
              <p>
                Мы сделаем ваше событие красивым при любом бюджете, опираясь на
                ваши пожелания и особенности площадки.
              </p>
            </motion.div>
          </motion.div>
        </div>
      </motion.section>
    </Layout>
  );
};

export default About;
