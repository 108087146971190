// import { useState, useEffect } from 'react';
//
// function useScreenWidth() {
//   const [screenWidth, setScreenWidth] = useState(window.innerWidth);
//
//   useEffect(() => {
//     const handleResize = () => {
//       setScreenWidth(window.innerWidth);
//     };
//
//     window.addEventListener('resize', handleResize);
//
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);
//
//   return screenWidth;
// }
//
// export default useScreenWidth;

import { useState, useEffect } from 'react';

function useScreenWidth() {
  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Проверяем, доступен ли объект window (только для клиентской среды)
    if (typeof window !== 'undefined') {
      // Добавляем слушателя события resize при монтировании компонента
      window.addEventListener('resize', handleResize);

      // Устанавливаем начальное значение ширины окна
      setScreenWidth(window.innerWidth);
    }

    // Удаляем слушателя события при размонтировании компонента
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  return screenWidth;
}

export default useScreenWidth;
