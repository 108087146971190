import {createSlice} from "@reduxjs/toolkit";
import event1 from '../images/events/1.jpg'
import event1_1 from '../images/events/1/1-1.jpg'
import event1_2 from '../images/events/1/1-2.jpg'
import event1_3 from '../images/events/1/1-3.jpg'
import event1_4 from '../images/events/1/1-4.jpg'
import event1_5 from '../images/events/1/1-5.jpg'
import event1_6 from '../images/events/1/1-6.jpg'
import event1_7 from '../images/events/1/1-7.jpg'
import event1_8 from '../images/events/1/1-8.jpg'
import event1_9 from '../images/events/1/1-9.jpg'
import event2 from '../images/events/2.jpg'
import event2_1 from '../images/events/2/2-1.jpg'
import event2_2 from '../images/events/2/2-2.jpg'
import event2_3 from '../images/events/2/2-3.jpg'
import event2_4 from '../images/events/2/2-4.jpg'
import event2_5 from '../images/events/2/2-5.jpg'
import event2_6 from '../images/events/2/2-6.jpg'
import event2_7 from '../images/events/2/2-7.jpg'
import event2_8 from '../images/events/2/2-8.jpg'
import event2_9 from '../images/events/2/2-9.jpg'
import event3 from '../images/events/3.jpg'
import event3_1 from '../images/events/3/3-1.jpg'
import event3_2 from '../images/events/3/3-2.jpg'
import event3_3 from '../images/events/3/3-3.jpg'
import event3_4 from '../images/events/3/3-4.jpg'
import event3_5 from '../images/events/3/3-5.jpg'
import event3_6 from '../images/events/3/3-6.jpg'
import event3_7 from '../images/events/3/3-7.jpg'
import event3_8 from '../images/events/3/3-8.jpg'
import event3_9 from '../images/events/3/3-9.jpg'
import event4 from '../images/events/4.jpg'
import event4_1 from '../images/events/4/4-1.jpg'
import event4_2 from '../images/events/4/4-2.jpg'
import event4_3 from '../images/events/4/4-3.jpg'
import event4_4 from '../images/events/4/4-4.jpg'
import event4_5 from '../images/events/4/4-5.jpg'
import event4_6 from '../images/events/4/4-6.jpg'
import event4_7 from '../images/events/4/4-7.jpg'
import event4_8 from '../images/events/4/4-8.jpg'
import event4_9 from '../images/events/4/4-9.jpg'
import event5 from '../images/events/5.jpg'
import event5_1 from '../images/events/5/5-1.jpg'
import event5_2 from '../images/events/5/5-2.jpg'
import event5_3 from '../images/events/5/5-3.jpg'
import event5_4 from '../images/events/5/5-4.jpg'
import event5_5 from '../images/events/5/5-5.jpg'
import event5_6 from '../images/events/5/5-6.jpg'
import event5_7 from '../images/events/5/5-7.jpg'
import event5_8 from '../images/events/5/5-8.jpg'
import event5_9 from '../images/events/5/5-9.jpg'
import event6 from '../images/events/6.jpg'
import event6_1 from '../images/events/6/6-1.jpg'
import event6_2 from '../images/events/6/6-2.jpg'
import event6_3 from '../images/events/6/6-3.jpg'
import event6_4 from '../images/events/6/6-4.jpg'
import event6_5 from '../images/events/6/6-5.jpg'
import event6_6 from '../images/events/6/6-6.jpg'
import event6_7 from '../images/events/6/6-7.jpg'
import event6_8 from '../images/events/6/6-8.jpg'
import event6_9 from '../images/events/6/6-9.jpg'
import event7 from '../images/events/7.jpg'
import event7_1 from '../images/events/7/7-1.jpg'
import event7_2 from '../images/events/7/7-2.jpg'
import event7_3 from '../images/events/7/7-3.jpg'
import event7_4 from '../images/events/7/7-4.jpg'
import event7_5 from '../images/events/7/7-5.jpg'
import event7_6 from '../images/events/7/7-6.jpg'
import event7_7 from '../images/events/7/7-7.jpg'
import event7_8 from '../images/events/7/7-8.jpg'
import event7_9 from '../images/events/7/7-9.jpg'
import event8 from '../images/events/8.jpg'
import event8_1 from '../images/events/8/8-1.jpg'
import event8_2 from '../images/events/8/8-2.jpg'
import event8_3 from '../images/events/8/8-3.jpg'
import event8_4 from '../images/events/8/8-4.jpg'
import event8_5 from '../images/events/8/8-5.jpg'
import event8_6 from '../images/events/8/8-6.jpg'
import event8_7 from '../images/events/8/8-7.jpg'
import event8_8 from '../images/events/8/8-8.jpg'
import event8_9 from '../images/events/8/8-9.jpg'
import event9 from '../images/events/9.jpg'
import event9_1 from '../images/events/9/9-1.jpg'
import event9_2 from '../images/events/9/9-2.jpg'
import event9_3 from '../images/events/9/9-3.jpg'
import event9_4 from '../images/events/9/9-4.jpg'
import event9_5 from '../images/events/9/9-5.jpg'
import event9_6 from '../images/events/9/9-6.jpg'
import event9_7 from '../images/events/9/9-7.jpg'
import event9_8 from '../images/events/9/9-8.jpg'
import event9_9 from '../images/events/9/9-9.jpg'
import event10 from '../images/events/10.jpg'
import event10_1 from '../images/events/10/10-1.jpg'
import event10_2 from '../images/events/10/10-2.jpg'
import event10_3 from '../images/events/10/10-3.jpg'
import event10_4 from '../images/events/10/10-4.jpg'
import event10_5 from '../images/events/10/10-5.jpg'
import event10_6 from '../images/events/10/10-6.jpg'
import event10_7 from '../images/events/10/10-7.jpg'
import event10_8 from '../images/events/10/10-8.jpg'
import event10_9 from '../images/events/10/10-9.jpg'
import event11 from '../images/events/11.jpg'
import event11_1 from '../images/events/11/11-1.jpg'
import event11_2 from '../images/events/11/11-2.jpg'
import event11_3 from '../images/events/11/11-3.jpg'
import event11_4 from '../images/events/11/11-4.jpg'
import event11_5 from '../images/events/11/11-5.jpg'
import event11_6 from '../images/events/11/11-6.jpg'
import event11_7 from '../images/events/11/11-7.jpg'
import event11_8 from '../images/events/11/11-8.jpg'
import event11_9 from '../images/events/11/11-9.jpg'
import event12 from '../images/events/12.jpg'
import event12_1 from '../images/events/12/12-1.jpg'
import event12_2 from '../images/events/12/12-2.jpg'
import event12_3 from '../images/events/12/12-3.jpg'
import event12_4 from '../images/events/12/12-4.jpg'
import event12_5 from '../images/events/12/12-5.jpg'
import event12_6 from '../images/events/12/12-6.jpg'
import event12_7 from '../images/events/12/12-7.jpg'
import event12_8 from '../images/events/12/12-8.jpg'
import event12_9 from '../images/events/12/12-9.jpg'
import event13 from '../images/events/13.jpg'
import event13_1 from '../images/events/13/13-1.jpg'
import event13_2 from '../images/events/13/13-2.jpg'
import event13_3 from '../images/events/13/13-3.jpg'
import event13_4 from '../images/events/13/13-4.jpg'
import event13_5 from '../images/events/13/13-5.jpg'
import event13_6 from '../images/events/13/13-6.jpg'
import event13_7 from '../images/events/13/13-7.jpg'
import event13_8 from '../images/events/13/13-8.jpg'
import event13_9 from '../images/events/13/13-9.jpg'
import event14 from '../images/events/14.jpg'
import event14_1 from '../images/events/14/14-1.jpg'
import event14_2 from '../images/events/14/14-2.jpg'
import event14_3 from '../images/events/14/14-3.jpg'
import event14_4 from '../images/events/14/14-4.jpg'
import event14_5 from '../images/events/14/14-5.jpg'
import event14_6 from '../images/events/14/14-6.jpg'
import event14_7 from '../images/events/14/14-7.jpg'
import event14_8 from '../images/events/14/14-8.jpg'
import event14_9 from '../images/events/14/14-9.jpg'
import event15 from '../images/events/15.jpg'
import event15_1 from '../images/events/15/15-1.jpg'
import event15_2 from '../images/events/15/15-2.jpg'
import event15_3 from '../images/events/15/15-3.jpg'
import event15_4 from '../images/events/15/15-4.jpg'
import event15_5 from '../images/events/15/15-5.jpg'
import event15_6 from '../images/events/15/15-6.jpg'
import event15_7 from '../images/events/15/15-7.jpg'
import event15_8 from '../images/events/15/15-8.jpg'
import event15_9 from '../images/events/15/15-9.jpg'
import event16 from '../images/events/16.jpg'
import event16_1 from '../images/events/16/16-1.jpg'
import event16_2 from '../images/events/16/16-2.jpg'
import event16_3 from '../images/events/16/16-3.jpg'
import event16_4 from '../images/events/16/16-4.jpg'
import event16_5 from '../images/events/16/16-5.jpg'
import event16_6 from '../images/events/16/16-6.jpg'
import event16_7 from '../images/events/16/16-7.jpg'
import event16_8 from '../images/events/16/16-8.jpg'
import event16_9 from '../images/events/16/16-9.jpg'
import event17 from '../images/events/17.jpg'
import event17_1 from '../images/events/17/17-1.jpg'
import event17_2 from '../images/events/17/17-2.jpg'
import event17_3 from '../images/events/17/17-3.jpg'
import event17_4 from '../images/events/17/17-4.jpg'
import event17_5 from '../images/events/17/17-5.jpg'
import event17_6 from '../images/events/17/17-6.jpg'
import event17_7 from '../images/events/17/17-7.jpg'
import event17_8 from '../images/events/17/17-8.jpg'
import event17_9 from '../images/events/17/17-9.jpg'
import event18 from '../images/events/18.jpg'
import event18_1 from '../images/events/18/18-1.jpg'
import event18_2 from '../images/events/18/18-2.jpg'
import event18_3 from '../images/events/18/18-3.jpg'
import event18_4 from '../images/events/18/18-4.jpg'
import event18_5 from '../images/events/18/18-5.jpg'
import event18_6 from '../images/events/18/18-6.jpg'
import event18_7 from '../images/events/18/18-7.jpg'
import event18_8 from '../images/events/18/18-8.jpg'
import event18_9 from '../images/events/18/18-9.jpg'

const initialState = {
  1: {
    img: event1,
    title: "Елена и Александр",
    photos: [
      event1_1,
      event1_2,
      event1_3,
      event1_4,
      event1_5,
      event1_6,
      event1_7,
      event1_8,
      event1_9,
    ],
  },
  2: {
    img: event2,
    title: "Александра и Дмитрий",
    photos: [
      event2_1,
      event2_2,
      event2_3,
      event2_4,
      event2_5,
      event2_6,
      event2_7,
      event2_8,
      event2_9,
    ],
  },
  3: {
    img: event3,
    title: "Полина и Александр",
    photos: [
      event3_1,
      event3_2,
      event3_3,
      event3_4,
      event3_5,
      event3_6,
      event3_7,
      event3_8,
      event3_9,
    ],
  },
  4: {
    img: event4,
    title: "Екатерина и Александр",
    photos: [
      event4_1,
      event4_2,
      event4_3,
      event4_4,
      event4_5,
      event4_6,
      event4_7,
      event4_8,
      event4_9,
    ],
  },
  5: {
    img: event5,
    title: "Ольга и Николай",
    photos: [
      event5_1,
      event5_2,
      event5_3,
      event5_4,
      event5_5,
      event5_6,
      event5_7,
      event5_8,
      event5_9,
    ],
  },
  6: {
    img: event6,
    photos: [
      event6_1,
      event6_2,
      event6_3,
      event6_4,
      event6_5,
      event6_6,
      event6_7,
      event6_8,
      event6_9,
    ],
  },
  7: {
    img: event7,
    title: "Дарья и Дмитрий",
    photos: [
      event7_1,
      event7_2,
      event7_3,
      event7_4,
      event7_5,
      event7_6,
      event7_7,
      event7_8,
      event7_9,
    ],
  },
  8: {
    img: event8,
    title: "Кристина и Алексей",
    photos: [
      event8_1,
      event8_2,
      event8_3,
      event8_4,
      event8_5,
      event8_6,
      event8_7,
      event8_8,
      event8_9,
    ],
  },
  9: {
    img: event9,
    title: "Анна и Ярослав",
    photos: [
      event9_1,
      event9_2,
      event9_3,
      event9_4,
      event9_5,
      event9_6,
      event9_7,
      event9_8,
      event9_9,
    ],
  },
  10: {
    img: event10,
    title: "Юбилей",
    photos: [
      event10_1,
      event10_2,
      event10_3,
      event10_4,
      event10_5,
      event10_6,
      event10_7,
      event10_8,
      event10_9,
    ],
  },
  11: {
    img: event11,
    title: "Виктория и Сергей",
    photos: [
      event11_1,
      event11_2,
      event11_3,
      event11_4,
      event11_5,
      event11_6,
      event11_7,
      event11_8,
      event11_9,
    ],
  },
  12: {
    img: event12,
    title: "Диана и Максим",
    photos: [
      event12_1,
      event12_2,
      event12_3,
      event12_4,
      event12_5,
      event12_6,
      event12_7,
      event12_8,
      event12_9,
    ],
  },
  13: {
    img: event13,
    title: "Алёна и Антон",
    photos: [
      event13_1,
      event13_2,
      event13_3,
      event13_4,
      event13_5,
      event13_6,
      event13_7,
      event13_8,
      event13_9,
    ],
  },
  14: {
    img: event14,
    title: "Вероника и Владислав",
    photos: [
      event14_1,
      event14_2,
      event14_3,
      event14_4,
      event14_5,
      event14_6,
      event14_7,
      event14_8,
      event14_9,
    ],
  },
  15: {
    img: event15,
    title: "Анастасия и Евгений",
    photos: [
      event15_1,
      event15_2,
      event15_3,
      event15_4,
      event15_5,
      event15_6,
      event15_7,
      event15_8,
      event15_9,
    ],
  },
  16: {
    img: event16,
    title: "Анастасия и Иван",
    photos: [
      event16_1,
      event16_2,
      event16_3,
      event16_4,
      event16_5,
      event16_6,
      event16_7,
      event16_8,
      event16_9,
    ],
  },
  17: {
    img: event17,
    title: "Екатерина и Артем",
    photos: [
      event17_1,
      event17_2,
      event17_3,
      event17_4,
      event17_5,
      event17_6,
      event17_7,
      event17_8,
      event17_9,
    ],
  },
  18: {
    img: event18,
    title: "Диана и Фарид",
    photos: [
      event18_1,
      event18_2,
      event18_3,
      event18_4,
      event18_5,
      event18_6,
      event18_7,
      event18_8,
      event18_9,
    ],
  },
};

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    // filterEvents(state, actions) {
    //   state.event = state.events.filter((f) => f.id === actions.payload);
    // },
  },
});

// Action creators are generated for each case reducer function
// export const { filterEvents } = eventsSlice.actions;

export default eventsSlice.reducer;
